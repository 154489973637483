<template>
  <div>
    <van-nav-bar title="订单详情" left-text="" left-arrow />
    <div class="back">
      <!-- 等待付款top -->
      <div class="box">
        <div class="await">
          <img
            class="await_img"
            src="../../../../static/image/succeed.png"
            alt=""
          />
        </div>
        <div class="payment_text">已完成</div>
      </div>

      <!-- 中间 -->
      <div class="middle">
        <img class="middle_img" src="../../../../static/image/wen.jpg" alt="" />
        <div>
          <div class="middle_text">黑神话《魔》</div>
          <div class="middle_hao">编号：#001</div>
        </div>
      </div>

      <div class="but_box">
        <van-button type="info" class="but" @click="jixu">继续支付</van-button>
      </div>

      <van-overlay :show="show" @click="zhe">
        <div class="wrapper" @click.stop>
          <div class="block" />
        </div>
      </van-overlay>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    jixu() {
      this.show = true;
    },
    zhe() {
      this.show = false;
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
.back {
  background: #f3f4f8;
  width: 100%;
  height: 100%;
}
// 等待付款top
.await {
  margin: 0 auto;
  .await_img {
    width: 53px;
    height: 53px;
  }
}

.box {
  padding-top: 0.8rem;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #e78f11;
}
.payment_text {
  font-size: 20px;
  margin-top: 0.5rem;
}
.payment_time {
  font-size: 15px;
  margin-top: 0.5rem;
}

.middle {
  margin-left: 17px;
  background-color: #ffffff;
  display: flex;
  border-radius: 5px;
  height: 76px;
  width: 341px;
  align-items: center;
  .middle_img {
    width: 50px;
    height: 50px;
    padding-left: 10px;
  }
  .middle_text {
    font-size: 14px;
    margin-left: 13px;
  }
  .middle_hao {
    color: #999999;
    font-size: 12px;
    margin-left: 13px;
  }
}

.but_box {
  width: 100vw;
  margin-top: 1.5rem;
}
.but {
  width: 8rem;
  height: 1.5rem;
  margin-left: 10%;
  margin-bottom: 50vh;
}
</style>
